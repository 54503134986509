<template>
  <el-card class="form-container" shadow="never">
    <el-form label-width="150px">
      <el-form-item label="Image">
        <single-upload v-model="formData.url"></single-upload>
      </el-form-item>
      <el-form-item label="Type">
        <el-select v-model="formData.type">
          <el-option v-for="item in typeOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Name">
        <el-input v-model="formData.name"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit()">提交</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import SingleUpload from '@/components/Upload/singleUpload';
import { create as createImageOption } from '@/api/imageOption';

export default {
  name: 'AddImageOption',
  components: { SingleUpload },
  data() {
    return {
      formData: {
        url: null,
        type: null,
        name: null,
      },
      typeOptions: ['membership_benefit_thumbnail', 'membership_cardBg'],
    };
  },
  methods: {
    onSubmit() {
      createImageOption(this.formData).then(() => {
        this.$message({
          message: 'Success',
          type: 'success',
          duration: 1000,
        });
        setTimeout(() => {
          location.reload();
        }, 1000);
      });
    },
  },
};

</script>
